import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import BaseLayout from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import Spacer from '../components/atoms/utility/Spacer';
import SEO from '../components/atoms/utility/seo';
import JumboPanel from '../components/atoms/JumboPanel';
import MeetTheTeam from '../components/organisms/MeetTheTeam';
import { RequiredGatsbyImagePropType } from '../utils/special-prop-types/required-gatsby-image-prop-type';

function AboutPageHeroContent() {
  return (
    <div className="-mt-[0%] sm:mt-[0%] lg:mt-[10%] text-center sm:text-left">
      <PrimaryHeader
        headerText="We Care About AI Agents"
        className="text-5xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[1.3] mb-2 sm:mb-4 lg:mb-4 text-white capitalize max-w-[350px] sm:max-w-sm md:max-w-md lg:max-w-md xl:max-w-lg mx-auto lg:mr-auto sm:ml-0 lg:ml-0 font-light"
      />
      <SubHeader
        headerText="Meet the team, and discover our passion for making it easy to manage, enhance, and enable collaboration with your own AI Agent."
        className="mb-4 md:mb-4 lg:mb-4 text-white mx-auto font-light max-w-[300px] sm:max-w-sm lg:max-w-md sm:mx-0"
      />
    </div>
  );
}

export default function TeamPage({
  data: {
    visionImg, missionImg, aboutHeroImg, aboutMobileHeroImg,
  },
}) {
  const jumboPanelOne = {
    image: {
      imageSrc: visionImg,
      imageAlt: 'People playing basketball with their AI Agents',
    },
    headline: {
      spanText: 'Our Vision',
      spanColor: '#6A67FF',
      headerText: '',
      headlineClassName: 'mb-4 text-center lg:text-left',
    },
    paragraph: {
      paragraphText:(
        <>
          We envision a world where individuals focus on creativity, strategy, and real connections—not repetitive tasks. Flockx is the AI-powered platform that transforms fragmented management into seamless engagement and growth through AI Agents, powered by{' '}
          <a href="https://fetch.ai" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            Fetch.ai
          </a>.
        </>
      ),
      paragraphClassName:
        'max-w-xl text-center lg:text-left text-grey-100',
    },
  };

  const jumboPanelTwo = {
    image: {
      imageSrc: missionImg,
      imageAlt: 'People watching the sunset in a canyon.',
    },
    headline: {
      spanText: 'The Mission',
      spanColor: '#FF7575',
      headerText: '',
      headlineClassName: 'mb-4 text-center lg:text-left',
    },
    paragraph: {
      paragraphText:
        'We build tools that simplify and amplify collaboration between AI Agents over the web. We reduce friction and empower people to manage, scale, and grow their AI Agents effortlessly.' ,

         
        
      paragraphClassName:
        'max-w-lg xl:max-w-[600px] text-center lg:text-left xl:-mr-8 text-grey-100',
    },
  };

  return (
    <BaseLayout
      heroContent={AboutPageHeroContent}
      mobileHeroImg={aboutMobileHeroImg}
      heroImg={aboutHeroImg}
    >
      <SEO
        title="Meet the Team Behind No Code AI Agent Deployment & Collaboration"
        keywords={[
          'ai agents',
          'agentic',
          'fetchai',
          'team members',
          'agentic',
          'vision',
          'mission statement',
        ]}
        description="Meet the team, and discover our passion for making it easy to manage, enhance, and enable collaboration with your own AI Agent."
      />
      <Spacer sizeY={1} />
      <Spacer className="block md:hidden" />
      <JumboPanel content={jumboPanelOne} isTeamPage />
      <Spacer />
      <Spacer className="block md:hidden" />
      <JumboPanel
        content={jumboPanelTwo}
        imageSide="left"
        isTeamPage
      />
      <Spacer />
      <Spacer className="block md:hidden" />
      <MeetTheTeam />
    </BaseLayout>
  );
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    visionImg: RequiredGatsbyImagePropType,
    missionImg: RequiredGatsbyImagePropType,
    aboutHeroImg: RequiredGatsbyImagePropType,
    aboutMobileHeroImg: RequiredGatsbyImagePropType,
  }).isRequired,
};

export const query = graphql`
    query AboutPageQuery {
        visionImg: file(name: { eq: "vision-img" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        missionImg: file(name: { eq: "mission-img" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        aboutHeroImg: file(name: { eq: "about-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        aboutMobileHeroImg: file(name: { eq: "about-mobile-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
