import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Headline from '../atoms/typography/Headline';

export default function MeetTheTeam() {
  const {
    devonImg,
    hImg,
    jasonImg,
    emelyImg,
    andrewImg,
    adityaImg,
    joshImg,
    warrenImg,
    haileyImg,
    markImg,
    saleemImg,
    erkanImg,
    davidImg,
  } = useStaticQuery(graphql`
        query MeetTheTeamQuery {
            devonImg: file(name: { eq: "Devon-Bleibtrey" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            hImg: file(name: { eq: "Humayun-Sheikh" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            jasonImg: file(name: { eq: "Jason-Coleman" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            emelyImg: file(name: { eq: "Emely-Sanchez" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            andrewImg: file(name: { eq: "Andrew-Riefenstahl" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            adityaImg: file(name: { eq: "Aditya" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            joshImg: file(name: { eq: "Josh-Raizon" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            warrenImg: file(name: { eq: "Warren-Bertram" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            haileyImg: file(name: { eq: "hailey-zhang-2" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            markImg: file(name: { eq: "Mark-Losey" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            }
            saleemImg: file(name: { eq: "Saleem-Skakeny" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            },
            erkanImg: file(name: { eq: "Erkan-Munishi" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            },
            davidImg: file(name: { eq: "David-Roberts" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                }
            },
        }
    `);

  const team = [
    {
      name: 'Humayun Sheikh',
      specialTitle: 'Founder',
      position: '',
      avatarImg: hImg,
    },
    {
      name: 'Devon Bleibtrey',
      specialTitle: 'Co-Founder',
      position: 'Chief Executive Officer',
      avatarImg: devonImg,
    },
    {
      name: 'Mark Losey',
      position: 'Chief Technology Officer',
      avatarImg: markImg,
    },
    {
      name: 'Jason Coleman',
      position: 'Chief Financial Officer',
      avatarImg: jasonImg,
    },
    {
      name: 'Josh Raizon',
      position: 'Executive Vice President',
      avatarImg: joshImg,
    },
    {
      name: 'Emely Sanchez',
      position: 'People Operations',
      avatarImg: emelyImg,
    },
    {
      name: 'Warren Bertram',
      position: 'Growth Designer',
      avatarImg: warrenImg,
    },
    {
      name: 'Saleem Skakeny',
      position: 'Product Design & Strategic Planning',
      avatarImg: saleemImg,
    },
    {
      name: 'Andrew Riefenstahl',
      position: 'Senior Frontend Engineer',
      avatarImg: andrewImg,
    },
    {
      name: 'Erkan Munishi',
      position: 'Growth Hacker',
      avatarImg: erkanImg,
    },
    {
      name: 'Aditya Gupta',
      position: 'Frontend Engineer',
      avatarImg: adityaImg,
    },
    {
      name: 'Hailey Zhang',
      position: 'User Experience Designer',
      avatarImg: haileyImg,
    },
    {
      name: 'David Roberts',
      position: 'Frontend Engineer',
      avatarImg: davidImg,
    },
  ];

  return (
    <section>
      <div className="flex justify-center items-center mb-20 lg:mb-32">
        <Headline spanText="Meet The Team" spanColor="#FF986C" />
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:grid-flow-row">
        {team.map((teamMember) => (
          <li
            className="flex items-center justify-center flex-col mb-20"
            key={`${teamMember.name}-${teamMember.position}`}
          >
            <div className="mb-4 rounded-3xl overflow-hidden">
              <GatsbyImage
                image={getImage(teamMember.avatarImg)}
                alt={teamMember.name}
                imgStyle={{ borderRadius: '24px' }}
              />
            </div>
            <div className="text-xl mb-2">{teamMember.name}</div>
            <div className="min-h-[50px]">
              {teamMember.specialTitle && (
                <div className="text-default text-xl text-center max-w-xs">
                  {teamMember.specialTitle}
                </div>
              )}
              <div className="text-default text-xl text-center max-w-xs">
                {teamMember.position}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
